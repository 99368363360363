<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.sys_roles')"

        :paths="[{title:$t('settings.title'),link:'settings'},{title:$t('settings.sys_users'),link:'system-users'}]"
        :btn="true"
        :btn-title="$t('settings.system_roles.new')"
        @btnAction="newSysUser"
    />

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table d-flex flex-column">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[

                { name: 'publish' },
                { name: 'action' },
              ]"
            >


              <template slot="publish" slot-scope="{ data }">
                <wameed-switch
                    :checked="data.item.publish ===1"
                    @onChange="()=>onChange(data.item)"/>
              </template>

              <template slot="action" slot-scope="{ data }"
                        v-if="data.item.type==null">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>

                  <b-dropdown-item
                      class="permission-edit-item"
                      v-permission="[$route.meta.pagePermissions.edit]"
                      :to="goToUpdate(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">

                      <edit-icon class="mx-2"/>
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                      class="permission-delete-item"
                      v-permission="[$route.meta.pagePermissions.delete]"
                      @click="deleteModal = true; modalData = data.item">
                    <span class="text-regular-14  text-danger">
                      <trash-icon class="mx-2 "/>
                      {{ $t('btn.delete') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>


    <warning-modal
        variant="danger"
        iconName="trash-icon"
        :visible="deleteModal"
        @close="deleteModal = false"
        @submitAction="deleteAction"
        :title="$t('settings.system_roles.modal.delete')"
        :subTitle="$t('settings.system_roles.modal.delete_desc')"
        :btnTitle="$t('btn.delete')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import WarningModal from "@/components/WarningModal";

import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import NameTile from "@/components/nameTile.table";
import AdModal from "@/views/pages/ads/components/adModal";


export default {
  components: {

    NameTile,
    WarningModal,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WameedSwitch,
    WTables,
  },
  data() {
    return {
      showModal: false,
      deleteModal: false,
      modalData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('common.status'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'text',
          data: [
            {
              id: 'active',
              title: this.$i18n.t('status.active'),
            },
            {
              id: 'not_active',
              title: this.$i18n.t('status.not_active'),
            },
          ],
        },
      ],
      tableFields: [
        {
          key: 'index',
          label: '#',
          sortable: false,

        },
        {
          key: 'name',
          label: this.$i18n.t('table.name'),
          sortable: false,
          tdClass: 'w-3/5'
        },




        {
          key: 'publish',
          label: this.$i18n.t('common.status'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/5'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/settings/sys_roles/getData',
      getTotal: 'admin/settings/sys_roles/getTotal',
    }),
  },
  created() {
    this.$store.dispatch('utilities/getVendorCategories');
    this.loadData();
  },
  methods: {
    ...mapActions({
      _getData: 'admin/settings/sys_roles/get',
      _deleteData: 'admin/settings/sys_roles/delete',
    }),

    newSysUser() {
      this.$router.push({name: 'system-roles-create'});
    },
    goToUpdate(id) {
      return {
        name: 'system-roles-update',
        params: {lang: this.$i18n.locale, id},
      };
    },

    onChange(item) {
      let publish = 1;
      if (item.publish === 1) {
        publish = 0;
      }

      this.$store.dispatch('admin/settings/sys_roles/toggle', {id: item.id, publish: publish})
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page:1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {

      this._getData(this.filterData);
    },

    deleteAction() {
      this.deleteModal = false
      this._deleteData({id: this.modalData.id});
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
